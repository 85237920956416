<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="3" class="d-flex align-center">
              <v-avatar size="44" :color="data.color" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ data.icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  {{ data.title }}
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ data.total }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>


    <v-col cols="12" md="12">
      <v-row class="match-height text-center justify-center">
        <v-col cols="12" sm="6">
          <statistics-card-vertical :change="totalReceber.change" :color="totalReceber.color" :icon="totalReceber.icon"
            :statistics="totalReceber.statistics" :stat-title="totalReceber.statTitle"
            :subtitle="totalReceber.subtitle"></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="6">
          <statistics-card-vertical :change="totalRecebido.change" :color="totalRecebido.color"
            :icon="totalRecebido.icon" :statistics="totalRecebido.statistics" :stat-title="totalRecebido.statTitle"
            :subtitle="totalRecebido.subtitle"></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" class="mt-6">
      <v-card>
        <v-card-text v-if="consultasHoje.length > 0">
          <v-card-title style="margin-left: 20px;">Consultas confirmadas hoje</v-card-title>
          <v-container style="max-width: 100%;">

            <v-timeline dense clipped v-for="item in consultasHoje" :key="item.hora">

              <v-timeline-item color="success" small>
                <v-row>
                  <v-col cols="7">
                    <b>Consulta:</b> {{ item.consulta }}<br>
                    <b>Paciente:</b> {{ item.paciente }} <br>
                    <b>Profissional:</b> {{ item.profissional }}
                  </v-col>
                  <v-col class="text-right mt-2" cols="5">
                    {{ item.hora }} Horas
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-container>


        </v-card-text>
        <v-card-text v-else>
          <v-card-title style="margin-left: 20px;">Consultas confirmadas hoje</v-card-title>
          <p class="">Sem consultas para o dia de hoje</p>
        </v-card-text>
      </v-card>
    </v-col>
    <!--  <v-col cols="6" md="12" >
      <v-row class="match-height text-center justify-center">
        <v-col cols="12" sm="12">
          <v-card>
            <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="'bar-chart'" :dataset-id-key="'label'"
              css-classes="" :styles="styles" :width="150" :height="30" />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
 -->

  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCurrencyUsd, mdiAccountCircleOutline, mdiAccountGroupOutline, mdiCalendarClock } from '@mdi/js'
import StatisticsCardVertical from './StatisticsCardVertical.vue'

import { Bar } from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  components: {
    StatisticsCardVertical,
    Bar
  },
  setup() {
    return {
      totalReceber: {
        statTitle: '',
        icon: mdiCurrencyUsd,
        color: 'success',
        subtitle: 'Total a receber das consultas (Mês)',
        statistics: 'R$ 0,00',
        change: '',
      },
      totalRecebido: {
        statTitle: '',
        icon: mdiCurrencyUsd,
        color: 'success',
        subtitle: 'Total recebido das consultas (Mês)',
        statistics: 'R$ 0,00',
        change: '',
      },
      statisticsData: [
        {
          title: 'Total de pacientes',
          total: '0',
          icon: mdiAccountCircleOutline,
          color: 'primary'
        },
        {
          title: 'Total de profissionais',
          total: '0',
          icon: mdiAccountGroupOutline,
          color: 'success'
        },
        {
          title: 'Total de consultas',
          total: '0',
          icon: mdiCalendarClock,
          color: 'warning'
        },
        {
          title: 'Total de consultas (Mês)',
          total: '0',
          icon: mdiCalendarClock,
          color: 'info'
        },
      ],
      chartData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Valor recebido por mês',
            backgroundColor: '#9155fd',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      },
      chartOptions: {
        responsive: true
      },
      styles: {
        type: Object,
        default: () => { }
      },
      plugins: {
        type: Object,
        default: () => { }
      },

      consultasHoje: [],
       model: null,
    }
  },

  methods: {
    pegarDados: function () {
      this.$http.post("dashboard", {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("idEmpresa"),
      })
        .then((res) => {
          if (res.data.status != undefined && res.data.status == 200) {
            var consultas = res.data.consultas
            var consultasMes = res.data.consultasMes
            var pacientes = res.data.pacientes
            var dentistas = res.data.dentistas

            this.statisticsData[0].total = pacientes.length
            this.statisticsData[1].total = dentistas.length
            this.statisticsData[2].total = consultas.length
            this.statisticsData[3].total = consultasMes.length

            var totalReceberMes = 0.00;
            var totalRecebidoMes = 0.00;
            for (var i = 0; i < consultasMes.length; i++) {
              totalReceberMes += parseFloat(consultasMes[i].valor);

              for (var j = 0; j < consultasMes[i].consultaPagamentos.length; j++) {
                totalReceberMes -= consultasMes[i].consultaPagamentos[j].valor;
                totalRecebidoMes += parseFloat(consultasMes[i].consultaPagamentos[j].valor);
              }

              var dataHoje = new Date();
              var dataMes = new Date(consultasMes[i].data);
              dataMes.setDate(dataMes.getDate() + 1);
              if (dataHoje.getDate() == dataMes.getDate()) {
                if (consultasMes[i].confirmacao == 2) {
                  this.consultasHoje.push({ paciente: consultasMes[i].paciente.paciente, hora: consultasMes[i].hora, profissional: consultasMes[i].dentistum.dentista, consulta: consultasMes[i].consulta })
                }
              }
            }

            for (var i = 0; i < consultas.length; i++) {
              var data = new Date(consultas[i].data)
              this.chartData.datasets[0].data[data.getMonth()] += parseFloat(consultas[i].valor);
            }

            this.totalReceber.statistics = totalReceberMes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
            this.totalRecebido.statistics = totalRecebidoMes.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

            this.$forceUpdate();

          } else if (res.data.status != undefined && res.data.status == 500) {
            this.$router.push('login')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  beforeMount() {
    var logado = localStorage.getItem("login")
    if (logado != undefined && logado == "true") {
      this.pegarDados()
    } else {
      this.$router.push('login')
    }
  },

  computed: {
    // uma função "getter" computada (computed getter)
    reversedMessage: function () {
      this.pegarDados()
    },
  }
}

</script>
